'use client'

import { Google, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, FormControl, FormLabel, IconButton, Input, Stack, Typography } from '@mui/joy'
import { signIn, useSession } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export default function LoginForm({
  error,
}: {
  error?: string | undefined
}): React.ReactElement {
  const searchParams = useSearchParams()
  const router = useRouter()

  const { data: session } = useSession()

  const callbackUrl = searchParams.get('callbackUrl') ?? '/'

  React.useEffect(() => {
    if (session) {
      router.push(callbackUrl)
    }
  }, [session, router, callbackUrl])

  const errorMessage = error
    ? ((error): string => {
        switch (error) {
          case 'CredentialsSignin':
            return 'Invalid credentials'
          case 'AccessDenied':
            return 'Access denied'
          default:
            return 'Sign in error - please try again'
        }
      })(error)
    : session ? 'Already signed in' : null

  return (
    <>
      {errorMessage && <Typography sx={{ color: 'var(--joy-palette-danger-500)' }}>{errorMessage}</Typography>}
      <CredentialsLogin callbackUrl={callbackUrl} error={errorMessage != null} disabled={!!session} />
      <GoogleLogin callbackUrl={callbackUrl} disabled={!!session} />
    </>
  )
}

function GoogleLogin({
  callbackUrl,
  disabled,
}: {
  callbackUrl: string
  disabled: boolean
}): React.ReactNode {
  const [loading, setLoading] = React.useState(false)

  function onLogin(): void {
    setLoading(true)
    signIn('google', { callbackUrl })
    setLoading(false)
  }

  return (
    <Button startDecorator={<Google />} onClick={onLogin} disabled={disabled} loading={loading}>
      Log in with Google
    </Button>
  )
}

export type Credentials = {
  email: string
  password: string
}

function CredentialsLogin({
  callbackUrl,
  error,
  disabled,
}: {
  callbackUrl: string
  error: boolean
  disabled: boolean
}): React.ReactNode {
  const methods = useForm<Credentials>()

  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const toggleShowPassword = () => setShowPassword(show => !show)

  function onLogin(data: Credentials): void {
    setLoading(true)
    signIn('credentials', { ...data, callbackUrl })
    setLoading(false)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onLogin)}>
        <Stack spacing={2}>
          <FormControl error={error} component={Stack} spacing={1}>
            <FormLabel>Email</FormLabel>
            <Input {...methods.register('email')} />
          </FormControl>
          <FormControl error={error} component={Stack} spacing={1}>
            <FormLabel>Password</FormLabel>
            <Input
              {...methods.register('password')}
              type={showPassword ? 'text' : 'password'}
              endDecorator={(
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              )}
            />
          </FormControl>
          <Button type="submit" disabled={disabled} loading={loading}>
            Login
          </Button>
        </Stack>
      </form>
    </FormProvider>
  )
}
